import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from 'src/app/shared/models';
import { environment } from '../../../environments/environment';


export interface ApiResponse<T> {
  status: boolean;
  result?: T;
  message?: string
}

@Injectable({
  providedIn: 'root'
})
export abstract class ApiService {
  protected host: string = environment.backendUrl;

  constructor(protected http: HttpClient) {
  }

  protected getToken(): string {
    return localStorage.getItem('auth') || '';
  }

  protected getHeaders(project: Project) {
    return new HttpHeaders({
      // 'Content-Type': 'application/json',
      'X-API-TOKEN': this.getToken(),
      projectIdentifier: project.identifier
    });
  }


  protected validateResponse<T>(response: ApiResponse<T>) {
    if (!response.status) {
      throw new Error(response.message);
    }
    return response.result;
  }
}
