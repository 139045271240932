import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Project, User } from 'src/app/shared/models';
import { ProjectService } from './project.service';


@Injectable({
  providedIn: 'root'
})
export class StateService {
  private projects$ = new BehaviorSubject<Project[]>(null);
  private currentProject$ = new BehaviorSubject<Project>(null);
  private currentRole$ = new BehaviorSubject<string>(null);
  private currentUser$ = new BehaviorSubject<User>(null);
  private configs$: ReplaySubject<any>;

  constructor(private projectService: ProjectService) { }

  get currentUser() {
    return this.currentUser$.asObservable();
  }

  setCurrentUser(user: User | null) {
    this.currentUser$.next(user);
  }

  get projects() {
    // return cached result if available
    if (this.projects$.value) {
      return this.projects$.asObservable();
    }

    return this.projectService.getProjects().pipe(
      mergeMap((projects) => {
        this.projects$.next(projects);
        // return same observable
        return this.projects$.asObservable();
      }),
    )
  }

  get currentProject() {
    return this.currentProject$.asObservable();
  }

  selectProject(identifier: string) {
    if (this.currentProject$.value && this.currentProject$.value.identifier === identifier) {
      return;
    }

    const project = this.projects$.value.find(p => p.identifier === identifier);
    this.currentProject$.next(project);
    const user = this.currentUser$.value;
    if (user && !user.isSuperAdmin && user.relatedProjects) {
      const relatedProject = user.relatedProjects.find(rp => rp.project.identifier === project.identifier);
      if (relatedProject) {
        this.currentRole$.next(relatedProject.role);
      }
    }
  }

  addProject(project: Project) {
    const projects = this.projects$.value ? [...this.projects$.value, project] : [project];
    this.projects$.next(projects);
  }

  get currentRole() {
    return this.currentRole$.asObservable();
  }

  updateProject(project: Project) {
    const index = this.projects$.value.findIndex(e => e.identifier === project.identifier);
    this.projects$.value[index] = project;
    this.projects$.next([...this.projects$.value]);
    this.currentProject$.next(null); 
  }

  get configs() {
    return this.configs$ ? this.configs$.asObservable() : null;
  }

  setConfigs(c: ReplaySubject<any>) {
    this.configs$ = c;
  }
}
