import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProjectService } from './core/services/project.service';
import { StateService } from './core/services/state.service';
import { UserService } from './core/services/user.service';
import { Project, User } from './shared/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  user: User;
  projects$: Observable<Project[]>;
  project: Project;
  projectControl: FormControl;
  isAdmin: boolean;

  constructor(
    protected userService: UserService,
    protected projectService: ProjectService,
    protected stateService: StateService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.stateService.currentUser.pipe(
      filter(u => !!u),
      // SET USER
      map(user => {
        this.user = user;
        return user;
      }),
    ).subscribe(
      // () => {this.projects$ = this.stateService.projects;}
    );

    this.stateService.currentRole.subscribe((role) => {
      this.isAdmin = role === "admin";
    })

    // navigate on project change
    // this.projectControl = new FormControl();
    // this.projectControl.valueChanges.subscribe(v => {
    //   this.stateService.selectProject(v);
    //   this.router.navigate([v, 'kyc']);
    // });

    // keep this to show/hide links
    this.stateService.currentProject.subscribe(p => {
      this.project = p;      
    });
    // in case when user opens route with projectId in it
    // this.stateService.currentProject.pipe(filter(p => !!p)).subscribe(p => {
    //   if (p.identifier !== this.projectControl.value) {
    //     this.projectControl.setValue(p.identifier, { emitEvent: false });
    //   }
    // });
  }

  logout() {
    this.userService.logout().subscribe(() => {
      console.log('redirecting to login page');
      this.router.navigate(['login']).then(() => {
        window.location.reload();
      });
    });
  }
}
