import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Project } from 'src/app/shared/models';
import { ApiResponse, ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ApiService {

  createProject(body: Partial<Project>) {
    return this.http.post<ApiResponse<Project>>(`${this.host}/project`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-API-TOKEN': this.getToken(),
      })
    }).pipe(
      map(this.validateResponse),
    );
  }

  updateProject(identifier: string, body: Partial<Project>) {
    return this.http.put<ApiResponse<Project>>(`${this.host}/project`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-API-TOKEN': this.getToken(),
        projectIdentifier: identifier
      })
    }).pipe(
      map(this.validateResponse),
    );
  }

  getProjects() {
    return this.http.get<ApiResponse<Project[]>>(`${this.host}/project`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-API-TOKEN': this.getToken(),
      })
    }).pipe(
      map(this.validateResponse),
    );
  }

}
