import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        // Ensure response body exists and status is false
        const body = (event as any).body;
        if (body && body.status === false) {
          throw body.message || 'An error occurred';
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {                    
        return throwError({message: error || 'Unknown error'});
      })
    );
  }
}
