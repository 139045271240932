import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Project } from 'src/app/shared/models';
import { StateService } from '../services/state.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  projects: Project[];
  filteredProjects: Project[];
  search: FormControl;
  private unsubscribe = new Subject();

  constructor(
    private stateService: StateService,
  ) { }

  ngOnInit() {
    this.stateService.projects.subscribe((projects) => {
      this.projects = projects;      
      this.reset();
    })

    this.search = new FormControl();
    this.search.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this.unsubscribe)
    ).subscribe((v) => {
      if (v) {
        this.filteredProjects = this.projects.filter(p => {
          return p.name.match(new RegExp(v, "gi"));
        });
      } else {
        this.reset();
      }
    });
  }

  reset() {
    this.filteredProjects = [...this.projects];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
