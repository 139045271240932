import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { PasswordComponent } from './core/password/password.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ProjectGuard } from './core/guards/project.guard';
import { HomeComponent } from './core/home/home.component';
import { NotfoundComponent } from './core/notfound/notfound.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'confirmation/:token',
    component: PasswordComponent,
  },
  {
    path: 'project',
    loadChildren: () => import('./project/project.module').then(mod => mod.ProjectModule),
    canActivate: [AuthGuard],
  },
  {
    path: ':projectId',
    component: HomeComponent,
    canActivate: [AuthGuard, ProjectGuard],
  },
  {
    path: ':projectId/kyc',
    loadChildren: () => import('./kyc/kyc.module').then(mod => mod.KycModule),
    canActivate: [AuthGuard, ProjectGuard]
  },
  {
    path: ':projectId/admin',
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
  },
  {
    path: '**',
    canActivate: [AuthGuard],
    component: NotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
