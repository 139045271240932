import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { StateService } from '../services/state.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private stateService: StateService,
    private router: Router
  ) {
  }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('authGuard called');
    if (!localStorage.getItem('auth')) {
      console.log("from guard, auth missing");
      this.router.navigate(['login']);
      return false;
    }

    return this.stateService.currentUser.pipe(
      take(1), // check once
      mergeMap((user) => {
        if (user) {
          return of(user);
        }
        // otherwise authLogin
        return this.userService.authLogin();
      }),
      map((res) => !!res),
      catchError((_err, _caught) => {
        // if there is an error, logout user, remove user token
        localStorage.removeItem('auth');
        this.router.navigate(['login']);
        return of(false);
      }),
    );
  }

}
