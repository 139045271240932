import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { User } from 'src/app/shared/models';
import { ApiResponse, ApiService } from './api.service';
import { StateService } from './state.service';


@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {

  constructor(
    protected http: HttpClient,
    protected stateService: StateService,
  ) {
    super(http);
  }

  authLogin() {
    console.log('authLogin called');
    return this.http.get(`${this.host}/user/auth`, {
      headers: new HttpHeaders({
        "X-API-TOKEN": this.getToken(),
      })
    }).pipe(
      map(this.validateResponse),
      map((user: User) => {
        this.stateService.setCurrentUser(user);
        return user;
      })
    );
  }

  login(body: { email: string, password: string }): Observable<User> {
    return this.http.post(`${this.host}/user/login`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      map(this.validateResponse),
      map((user: User) => {
        if (!user.token) {
          throw new Error('Login failed');
        }
        localStorage.setItem('auth', user.token);
        this.stateService.setCurrentUser(user);
        return user;
      })
    );
  }

  logout() {
    console.log('logout called');
    return this.http.post(`${this.host}/user/logout`, {}, {
      headers: new HttpHeaders({
        "X-API-TOKEN": localStorage.getItem('auth') || ''
      })
    }).pipe(
      map(this.validateResponse),
      map(() => {
        this.stateService.setCurrentUser(null);
        console.log('removing token');
        localStorage.removeItem('auth');
        return true;
      }),
    );
  }

  inviteUser(body: { [key: string]: any }) {
    return this.stateService.currentProject
      .pipe(
        take(1),
        map(project => this.getHeaders(project)),
        mergeMap(headers => this.http.post<ApiResponse<any>>(`${this.host}/user/invite`, body, { headers })),
        map(this.validateResponse),
      );
  }

  listUsers() {
    return this.stateService.currentProject
      .pipe(
        take(1),
        map(project => this.getHeaders(project)),
        mergeMap(headers => this.http.get<ApiResponse<User[]>>(`${this.host}/user`, { headers })),
        map(this.validateResponse),
      );
  }

  disableUser(id: number) {
    return this.stateService.currentProject
      .pipe(
        take(1),
        map(project => this.getHeaders(project)),
        mergeMap(headers => this.http.delete<ApiResponse<any>>(`${this.host}/user/${id}`, { headers })),
        map(this.validateResponse),
      );
  }

  checkToken(body: { [key: string]: any }) {
    return this.http.post<ApiResponse<any>>(`${this.host}/user/checktoken`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(
      map(this.validateResponse),
    );
  }

  setPassword(body: { [key: string]: any }) {
    return this.http.post<ApiResponse<any>>(`${this.host}/user/confirm`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(
      map(this.validateResponse),
    );
  }
}
