import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SafePipe } from './pipes/safe.pipe';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  //=====================
  MatSnackBarModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatMomentDateModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatNativeDateModule
];


@NgModule({
  imports: modules,
  exports: [
    ...modules,
    SafePipe
  ],
  declarations: [
    SafePipe
  ],
  providers: []
})
export class SharedModule { }