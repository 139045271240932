import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { PasswordComponent } from './password/password.component';


@NgModule({
  imports: [
    AppRoutingModule,
    SharedModule,
  ],
  exports: [
    NotfoundComponent,
  ],
  declarations: [
    NotfoundComponent,
    HomeComponent,
    LoginComponent,
    PasswordComponent,
  ],
  providers: []
})
export class CoreModule { }