import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { UserService } from 'src/app/core/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take, mergeMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  passwordForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(
      take(1),
      mergeMap((p) => {
        const token = p.get('token');
        return this.userService.checkToken({ token }).pipe(map(() => token));
      }),
    ).subscribe(
      (token: string) => this.buildForm(token),
      () => {
        this.router.navigate(['login']);
        this.snackBar.open('Link invalid', 'close', { duration: 10000 });
      },
    );
  }

  buildForm(token: string) {
    this.passwordForm = new FormGroup({
      password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      passwordConfirmation: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      token: new FormControl(token, [Validators.required]),
    }, {
      validators: (control: FormGroup) => {
        return (control.get('password').value !== control.get('passwordConfirmation').value)
          ? { passwordMissmatch: true }
          : null;
      }
    });
  }

  setPassword() {
    this.userService.setPassword(this.passwordForm.value).subscribe(
      () => this.router.navigate(['login']),
      (err) => this.snackBar.open(err.message || err, 'close', { duration: 10000 }),
    );
  }
}
