import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectGuard implements CanActivate {

  constructor(
    private stateService: StateService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('projectGuard called');

    const roles: string[] = route.data["roles"] || [];
    return this.stateService.projects.pipe(
      // PROJECT CHECK
      take(1),
      map((projects) => {
        const project = projects.find(p => p.identifier === route.params['projectId']);
        if (project) {
          this.stateService.selectProject(project.identifier);
        }
        return !!project;
      }),
      // ROLE CHECK
      // mergeMap(() => this.stateService.currentUser),
      mergeMap(() => combineLatest([this.stateService.currentRole, this.stateService.currentUser])),
      take(1),
      map(([role, user]) => {
        const hasRole = !roles.length || !!user.isSuperAdmin || roles.includes(role);
        if (!hasRole) {
          this.router.navigate([""]);
        }
        return hasRole;
      })
    );
  }

}
